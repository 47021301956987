.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit-active {
  opacity: 0;
}
.toastui-chart-tooltip-container {
  display: flex;
  min-height: 30px;
  background-color: rgba(85, 85, 85, 0.95);
  border-radius: 5px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
}
.hidden {
  display: none;
}
.toastui-chart-series-name {
  display: flex;
  flex-direction: column;
  gap: 10px
}
