.ChartWithLegend {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.ChartWithLegend .Legend {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.ChartWithLegend .LegendSpacer,
.ChartWithLegend .LegendWrapper {
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;

  /* allow legend and spacer to shrink */
  min-width: 0;
  min-height: 0;
}

.ChartWithLegend .LegendSpacer {
  visibility: hidden;
}

.ChartWithLegend .Chart {
  flex-shrink: 0;
  position: relative;
}

/* SMALL */
.ChartWithLegend.small .Legend {
  display: none;
}

.ChartWithLegend.small .Chart {
  flex: 1;
}

/* VERTICAL */
.ChartWithLegend.vertical {
  flex-direction: column-reverse;
}

.ChartWithLegend.vertical .Legend {
  flex-shrink: 1;
  overflow: hidden;
}

.ChartWithLegend.vertical .LegendWrapper {
  flex-direction: column;
}

.ChartWithLegend.vertical.flexChart .Legend {
  flex-grow: 0;
  flex-shrink: 0;
}

.ChartWithLegend.vertical.flexChart .Chart {
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 75%;
}
/* GlarusBi styles START */
.ChartWithLegend.top.vertical .Legend {
  padding-top: 2rem;
  flex: 1 1;
  flex-grow: 1;
  flex-basis: 0;
  height: max-content;
  flex-shrink: 1;
}

.ChartWithLegend.top.vertical .Chart {
  flex: 1 1;
}

.ChartWithLegend.top.vertical.flexChart .Legend {
  flex-grow: 1;
  flex-shrink: 0;
}

.ChartWithLegend.bottom.vertical.flexChart .Legend {
  flex-grow: 1;
  flex-shrink: 0;
}
/* GlarusBi styles END */

/* HORIZONTAL */
.ChartWithLegend.horizontal {
  flex-direction: row;
}

.ChartWithLegend.horizontal .Legend {
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;
}

.ChartWithLegend.horizontal .LegendWrapper {
  flex-direction: row;
}

.ChartWithLegend.horizontal.flexChart .Legend {
  flex-grow: 0;
  flex-shrink: 0;
}

.ChartWithLegend.horizontal.flexChart .Chart {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 66%;
}

/* GlarusBi styles START */

.ChartWithLegend.top.horizontal .Legend {
  padding-top: 2rem;
  flex: 1 1;
  flex-grow: 1;
  flex-basis: 0;
  height: max-content;
  flex-shrink: 1;
}

.ChartWithLegend.top.horizontal .Chart {
  flex: 1 1;
}

.ChartWithLegend.top.horizontal.flexChart .Legend {
  flex-grow: 1;
  flex-shrink: 0;
}

.ChartWithLegend.bottom.horizontal.flexChart .Legend {
  flex-grow: 1;
  flex-shrink: 0;
}

/* LEGEND POSITION TOP */
.ChartWithLegend.top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}

.ChartWithLegend.top .LegendSpacer,
.ChartWithLegend.top .LegendWrapper {
  flex-grow: 0;
}

.ChartWithLegend.top .Legend {
  padding-top: 4rem;
  flex: 1 1;
  flex-grow: 1;
  flex-basis: 0;
  height: max-content;
  flex-shrink: 1;
}

.ChartWithLegend.top .Chart {
  flex: 1 1;
}

/* LEGEND POSITION BOTTOM */

.ChartWithLegend.bottom {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.ChartWithLegend.bottom .LegendSpacer,
.ChartWithLegend.bottom .LegendWrapper {
  flex-grow: 0;
}

.ChartWithLegend.bottom .Legend {
  flex: 1 1;
  height: inherit;
  flex-grow: 1;
  flex-shrink: 1;
}

.ChartWithLegend.bottom .Chart {
  padding-top: 4rem;
  flex: 1 1;
}

/* LEGEND POSITION RIGHT */

.ChartWithLegend.right {
  flex-direction: row-reverse;
}

/* GlarusBi styles END */
